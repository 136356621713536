<template>
	<div class="experience">
		<div class="top">
			<div class="top-img">
				<img v-lazy="topImg" :key="topImg" alt="top" />
			</div>
		</div>
		<div class="content">
			<div class="center-code">
				<img v-lazy="codeImg" :key="codeImg" alt="小程序码" />
				<p>关注"慧听智能语音演示系统"</p>
				<span>体验公司产品</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'experience',
	data() {
		return {
			topImg: require('@/assets/img/experience/experienceBg.jpg'),
			codeImg: require('@/assets/img/xcx.png'),
		}
	},
	mounted() {},
	watch: {},
	methods: {},
	components: {},
	computed: {
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.experience {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	.top {
		width: 100%;
		margin-bottom: 0.4rem;
		position: relative;
		.top-img {
			width: 100%;
			height: 178px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.content {
		.center-code {
			min-height: 300px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				width: 102px;
				height: 102px;
			}
			p {
				height: 20px;
				font-size: 16px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #333333;
				line-height: 20px;
				margin: 20px 0;
			}
			span {
				font-size: 14px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				line-height: 20px;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.experience {
		.content {
			width: 90%;
		}
	}
}
</style>
